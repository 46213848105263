<template>
  <div>
    <div v-if="reviews.length > 0">
      <div
        v-for="review in reviews"
        :key="review.id_pesanan"
        class="border border-gray-200 rounded-xl p-4 shadow-xl mt-4"
      >
        <ValidationObserver v-slot="{ invalid }" class="md:grid md:grid-cols-2">
          <div class="md:grid md:grid-cols-5 flex flex-row gap-4 md:gap-0">
            <img
              :src="review.foto"
              class="col-span-2 rounded-xl object-contain w-20 h-20 sm:h-40 sm:w-40 md:h-full md:w-full"
            />
            <div class="col-span-3 pl-4">
              <div class="text-primary font-bold text-lg">
                How was your trip ?
              </div>
              <div class="font-bold text-lg mt-2">
                {{ review.nama_paket }}
              </div>
              <div class="font-semibold">{{ review.tipe }}</div>
              <div class="font-semibold">{{ review.nama_canam_safari }}</div>
              <ValidationProvider name="rating" rules="required">
                <star-rating
                  v-model="review.rating"
                  class="mt-2"
                  :show-rating="false"
                  :active-on-click="true"
                  :padding="2"
                  :star-size="30"
                ></star-rating>
                <div class="text-sm font-medium mt-2">
                  (1 is disappointing, 5 is awesome)
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div>
            <div class="flex justify-between mt-4 md:mt-0 items-end">
              <div class="font-semibold">Any words for your trip ?</div>
              <div class="flex flex-col">
                <div class="text-right font-medium text-sm">
                  {{
                    $moment(`${review.tgl} ${review.jam_mulai}`).format(
                      "DD MMM - HH:mmA"
                    )
                  }}
                </div>
                <div class="font-medium text-sm">
                  Order {{ review.nobukti }}
                </div>
              </div>
            </div>
            <ValidationProvider name="address" rules="required">
              <textarea
                v-model="review.comment"
                class="border border-gray-500 rounded-lg focus:outline-none w-full py-2 pl-2 my-2 font-medium"
              />
            </ValidationProvider>
            <div class="flex justify-between">
              <div class="flex items-start">
                <div class="flex items-center">
                  <font-awesome-icon icon="fa-solid fa-eye-slash" size="sm" />
                  <!-- <font-awesome-icon v-else icon="fa-solid fa-eye" size="sm" /> -->
                  <div class="text-sm ml-2 mr-8 font-semibold">
                    Hide your name
                  </div>
                  <toggle-button
                    v-model="review.hideName"
                    :color="{
                      checked: '#1f7885',
                      unchecked: '#ffffff',
                      disabled: '#ccc',
                    }"
                    switch-color="#c7c5c5"
                    class="border border-black rounded-full"
                  />
                </div>
              </div>
              <button
                @click="createReview(review)"
                class="button-submit"
                :disabled="invalid"
              >
                Done
              </button>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
    <div v-else class="font-bold text-center text-xl mt-8">
      Please do a booking first...
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountReviewIndex",

  data() {
    return {
      reviews: [],
    };
  },

  created() {
    this.$store.commit("setBreadcrumbItems", [
      { text: "Home", routeName: "Home" },
      { text: "Account", routeName: "AccountProfile" },
      { text: "Reviews", routeName: "AccountReviewIndex" },
      { text: "Waiting for review", routeName: "AccountReviewIndex" },
    ]);

    this.getPendingReviews();
  },

  methods: {
    async getPendingReviews() {
      this.$store.commit("setOverlayLoading", true);

      const data = new FormData();
      data.append("txtUser", this.$store.state.user.id_user);

      try {
        const response = await this.$http.post(
          `${this.$apiTripwe}/ic/jetski-android-new/list_review_belum_v16.php`,
          data
        );

        if (response.data === "") {
          throw "Get reviews failed";
        }

        this.reviews = response.data.map((val) => ({
          ...val,
          rating: null,
          hideName: false,
          comment: null,
        }));
      } catch (error) {
        this.$toasted.global.error(error);
      }

      this.$store.commit("setOverlayLoading", false);
    },
    async createReview(review) {
      this.$store.commit("setOverlayLoading", true);

      const data = new FormData();
      data.append("txtIdUser", this.$store.state.user.id_user);
      data.append("txtIdPaket", review.id_paket);
      data.append("txtIdPesanan", review.id_pesanan);
      data.append("txtBintang", review.rating);
      data.append("txtKomentar", review.comment);
      data.append(
        "txtJenis",
        review.tipe == "SAFARI" ? 2 : review.tipe == "OFFROAD" ? 3 : 4
      );
      data.append("txtHideNama", review.hideName ? 1 : 0);
      data.append("mobile", "android");

      try {
        const response = await this.$http.post(
          `${this.$apiTripwe}/ic/jetski-android-new/insert_review_pesanan_v16.php`,
          data
        );

        if (response.data != "success") {
          throw "Create review failed";
        }

        await this.getPendingReviews();
      } catch (error) {
        this.$toasted.global.error(error);
      }

      this.$store.commit("setOverlayLoading", false);
    },
  },
};
</script>
